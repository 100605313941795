exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-eurojackpot-js": () => import("./../../../src/pages/eurojackpot.js" /* webpackChunkName: "component---src-pages-eurojackpot-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jokeri-js": () => import("./../../../src/pages/jokeri.js" /* webpackChunkName: "component---src-pages-jokeri-js" */),
  "component---src-pages-lotto-js": () => import("./../../../src/pages/lotto.js" /* webpackChunkName: "component---src-pages-lotto-js" */),
  "component---src-pages-vakio-js": () => import("./../../../src/pages/vakio.js" /* webpackChunkName: "component---src-pages-vakio-js" */),
  "component---src-pages-viking-js": () => import("./../../../src/pages/viking.js" /* webpackChunkName: "component---src-pages-viking-js" */)
}

